import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2-2\\@90%1RM`}</p>
    <p>{`Single Leg Barbell RDL’s 4-4-4-4-4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`100-Double Unders`}</p>
    <p>{`25-Back Squats (155/105)`}</p>
    <p>{`20-KBS’s (53/35)`}</p>
    <p>{`75-Double Unders`}</p>
    <p>{`20-Back Squats`}</p>
    <p>{`15-KBS`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`15-Back Squats`}</p>
    <p>{`10-KBS`}</p>
    <p>{`25-Double Unders`}</p>
    <p>{`10-Back Squats`}</p>
    <p>{`5-KBS`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      